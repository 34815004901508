import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
const routes: Routes = [
  // { path: '', redirectTo: 'pagecats/1/0', pathMatch: 'full' },
  { path: "", redirectTo: "first", pathMatch: "full" },
  { path: "login", loadChildren: "./login/login.module#LoginPageModule" },
  {
    path: "register",
    loadChildren: "./register/register.module#RegisterPageModule",
  },
  {
    path: "home",
    loadChildren: "./home/home.module#HomePageModule",
    canActivate: [AuthGuard],
  },
  // { path: 'termcat/:type', loadChildren: './termcat/termcat.module#TermcatPageModule' },
  // { path: 'terms/:pageCatId', loadChildren: './terms/terms.module#TermsPageModule' },
  // { path: 'term/:pageCatId/:pageId', loadChildren: './term/term.module#TermPageModule' },
  {
    path: "profile",
    loadChildren: "./profile/profile.module#ProfilePageModule",
    canActivate: [AuthGuard],
  },
  { path: "forget", loadChildren: "./forget/forget.module#ForgetPageModule" },
  {
    path: "pageview/:pageId",
    loadChildren: "./pageview/pageview.module#PageviewPageModule",
  },
  {
    path: "modal-content",
    loadChildren: () =>
      import("./modal-content/modal-content.module").then(
        (m) => m.ModalContentPageModule
      ),
  },
  {
    path: "changepass",
    loadChildren: () =>
      import("./changepass/changepass.module").then(
        (m) => m.ChangepassPageModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "changeusername",
  //   loadChildren: () =>
  //     import("./changeusername/changeusername.module").then(
  //       (m) => m.ChangeusernamePageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "invitors",
  //   loadChildren: () =>
  //     import("./invitors/invitors.module").then((m) => m.InvitorsPageModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "transfer",
  //   loadChildren: () =>
  //     import("./transfer/transfer.module").then((m) => m.TransferPageModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "pagecats/:shopId/:charityId",
  //   loadChildren: () =>
  //     import("./pagecats/pagecats.module").then((m) => m.PagecatsPageModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "bazarcats/:bazarId/:pageCatId/:pageId",
  //   loadChildren: () =>
  //     import("./bazarcats/bazarcats.module").then((m) => m.BazarcatsPageModule),
  // }, 
  // {
  //   path: "bazarpage/:bazarId/:pageCatId/:pageId",
  //   loadChildren: () =>
  //     import("./bazarpage/bazarpage.module").then((m) => m.bazarpagePageModule),
  // },
  // {
  //   path: "bazar/:bazarId/:pageCatId/:pageId",
  //   loadChildren: () =>
  //     import("./bazar/bazar.module").then((m) => m.BazarPageModule),
  // },
  // {
  //   path: "bazarche/:pageCatId",
  //   loadChildren: () =>
  //     import("./bazarche/bazarche.module").then((m) => m.BazarchePageModule),
  //   // canActivate: [AuthGuard],
  // },
  {
    path: "address/:source",
    loadChildren: () =>
      import("./address/address.module").then((m) => m.AddressPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "shopcart/:charityId",
  //   loadChildren: () =>
  //     import("./shopcart/shopcart.module").then((m) => m.ShopcartPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: "checkout/:adrid/:charityId",
    loadChildren: () =>
      import("./checkout/checkout.module").then((m) => m.CheckoutPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "orders/:status",
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "orderdetails/:orderId",
    loadChildren: () =>
      import("./orderdetails/orderdetails.module").then(
        (m) => m.OrderdetailsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "messages",
    loadChildren: () =>
      import("./messages/messages.module").then((m) => m.MessagesPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pays",
    loadChildren: () =>
      import("./pays/pays.module").then((m) => m.PaysPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "deinlieferbuddys",
  //   loadChildren: () =>
  //     import("./deinlieferbuddys/deinlieferbuddys.module").then((m) => m.deinlieferbuddysPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: "contact/:msgId/:orderId",
    loadChildren: () =>
      import("./contact/contact.module").then((m) => m.ContactPageModule),
  },
  {
    path: "faq",
    loadChildren: () => import("./faq/faq.module").then((m) => m.FaqPageModule),
  },
  // {
  //   path: "gallerypopover",
  //   loadChildren: () =>
  //     import("./gallerypopover/gallerypopover.module").then(
  //       (m) => m.GallerypopoverPageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "charities",
  //   loadChildren: () =>
  //     import("./charities/charities.module").then((m) => m.CharitiesPageModule),
  // },
  // {
  //   path: "charitiesbazar",
  //   loadChildren: () =>
  //     import("./charitiesbazar/charitiesbazar.module").then((m) => m.charitiesbazarPageModule),
  // },
  // {
  //   path: "bazars/:isCharity",
  //   loadChildren: () =>
  //     import("./bazars/bazars.module").then((m) => m.BazarsPageModule),
  // },
  // {
  //   path: "charitypopover",
  //   loadChildren: () =>
  //     import("./charitypopover/charitypopover.module").then(
  //       (m) => m.CharitypopoverPageModule
  //     ),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "shoppopover",
  //   loadChildren: () =>
  //     import("./shoppopover/shoppopover.module").then(
  //       (m) => m.ShoppopoverPageModule
  //     ),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "bazarpopover",
  //   loadChildren: () =>
  //     import("./bazarpopover/bazarpopover.module").then(
  //       (m) => m.BazarpopoverPageModule
  //     ),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "bazarorderpopover",
  //   loadChildren: () =>
  //     import("./bazarorderpopover/bazarorderpopover.module").then(
  //       (m) => m.BazarorderpopoverPageModule
  //     ),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "bazarorderkalapopover",
  //   loadChildren: () =>
  //     import("./bazarorderkalapopover/bazarorderkalapopover.module").then(
  //       (m) => m.bazarorderkalapopoverPageModule
  //     ),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "checkouttime",
  //   loadChildren: () =>
  //     import("./checkouttime/checkouttime.module").then(
  //       (m) => m.CheckouttimePageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "activedevice",
  //   loadChildren: () =>
  //     import("./activedevice/activedevice.module").then(
  //       (m) => m.ActivedevicePageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "user-friend/:type",
  //   loadChildren: () =>
  //     import("./user-friend/user-friend.module").then(
  //       (m) => m.UserFriendPageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "store-discount",
  //   loadChildren: () =>
  //     import("./store-discount/store-discount.module").then(
  //       (m) => m.StoreDiscountPageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "user-notify",
  //   loadChildren: () =>
  //     import("./user-notify/user-notify.module").then(
  //       (m) => m.UserNotifyPageModule
  //     ),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "stores",
  //   loadChildren: () =>
  //     import("./stores/stores.module").then((m) => m.StoresPageModule),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "store/:storeId",
  //   loadChildren: () =>
  //     import("./store/store.module").then((m) => m.StorePageModule),
  //   // canActivate: [AuthGuard],
  // },
  // {
  //   path: "intro/:randomcode",
  //   loadChildren: () =>
  //     import("./intro/intro.module").then((m) => m.IntroPageModule),
  // },
  // {
  //   path: "intro-pagecats/:shopId/:charityId",
  //   loadChildren: () =>
  //     import("./intro-pagecats/intro-pagecats.module").then(
  //       (m) => m.IntroPagecatsPageModule
  //     ),
  // },
  {
    path: "first",
    loadChildren: () =>
      import("./first/first.module").then((m) => m.FirstPageModule),
  },
  // {
  //   path: 'bazarche',
  //   loadChildren: () => import('./bazarche/bazarche.module').then( m => m.BazarchePageModule)
  // },
  // {
  //   path: 'takhfiforg',
  //   loadChildren: () => import('./takhfiforg/takhfiforg.module').then( m => m.takhfiforgPageModule)
  // },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then( m => m.ReportsPageModule)
  },
  // {
  //   path: 'provincepopover',
  //   loadChildren: () => import('./provincepopover/provincepopover.module').then( m => m.ProvincepopoverPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
