// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: 10801,
  production: false,
  apilocal: "http://127.0.0.1:12821/api",
  srclocal: "http://127.0.0.1:12821/upload",
  // api: "http://127.0.0.1:12822/api",
  // src: "http://127.0.0.1:12822/upload",
  api: 'https://www.deinlieferbuddy.com/api',
  src: 'https://www.deinlieferbuddy.com/upload',
  // api: "http://10.0.2.2:12822/api",
  // src: "http://10.0.2.2:12822/upload",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
