import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { PlaylistService } from "./playlist.service";
import { UserService } from "./user.service";
import { HttpClientModule } from "@angular/common/http";
import { ModalContentPage } from "./modal-content/modal-content.page";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
// import { Geolocation } from "@ionic-native/geolocation/ngx";
// import { GaoDeLocation } from "@ionic-native/gao-de-location/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from '../environments/environment';
// import * as ionicGalleryModal from "ionic-gallery-modal";
// import { HAMMER_GESTURE_CONFIG } from "@angular/platform-browser";
// import { CommonModule } from '@angular/common';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Network } from '@capacitor/core';
// import { NetworkInterface } from '@ionic-native/network-interface/ngx';

@NgModule({
  declarations: [AppComponent, ModalContentPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: true }),
    // CommonModule,
    // ionicGalleryModal.GalleryModalModule,
  ],
  entryComponents: [ModalContentPage],
  providers: [
    StatusBar,
    SplashScreen,
    // { provide: HAMMER_GESTURE_CONFIG,useClass: ionicGalleryModal.GalleryModalHammerConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    PlaylistService,
    SocialSharing,
    UserService,
    // File,
    // Geolocation,
    // GaoDeLocation,
    Camera,
    OneSignal,
    Deeplinks,
    AndroidPermissions,
    // NetworkInterface
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
