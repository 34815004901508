import { Component, ViewChildren, QueryList } from "@angular/core";

import {
  Platform,
  MenuController,
  ModalController,
  NavController,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
// import { UserService } from "./user.service";
import { Router } from "@angular/router";
import { ModalContentPage } from "./modal-content/modal-content.page";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { OrdersPage } from "./orders/orders.page";
import { HomePage } from "./home/home.page";
import { UserService } from "./user.service";
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  // message: string;
  // isAuth: boolean = false;

  // @ViewChildren(IonRouterOutlet) routerOutlets;//: QueryList;
  isLoggedIn: boolean;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    // public events: Events,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    public modalController: ModalController,
    // private userService: UserService,
    private router: Router,
    private oneSignal: OneSignal,
    private deeplinks: Deeplinks,
    private userService: UserService,
    updates: SwUpdate
  ) {
    // this.platform.backButton.subscribeWithPriority(10, () => {
    //   console.log('Handler was called!');
    // });
    // this.listenToLoginEvents();
    updates.available.subscribe((event) => {
      // if (confirm("نسخه جدید در دسترس می باشد! آیا Refresh انجام شود؟")) {
        if (confirm("Neue Version ist verfügbar! Sollte eine Aktualisierung durchgeführt werden?")) {
        updates.activateUpdate().then(() => document.location.reload());
      }
    });
    this.initializeApp();
    this.backButtonEvent();
  }

  backButtonEvent() {
    // this.platform.backButton.subscribe(() => {
    //     this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
    //         if (this.router.url === '/home') {
    //             navigator['app'].exitApp();
    //         } else {
    //             window.history.back();
    //         }
    //     });
    // });
    this.platform.backButton.subscribe(() => {
      if (this.router.url === "/first") {
        // navigator["app"].exitApp();
      } else {
        window.history.back();
      }
    });
  }

  closeMenu() {
    this.menuCtrl.close();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.overlaysWebView(false);
      //this.statusBar.styleDefault();
      this.statusBar.styleLightContent();
      // this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString("#F36705");
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      // this.userService.currentMessage.subscribe(
      //   message => (this.message = message)
      // );
      // this.userService.currentAuth.subscribe(isAuth => (this.isAuth = isAuth));
      // if (this.userService.isLoggedIn) {
      //   this.userService.changeAuth(true);
      // }
      // if (!this.isAuth) {
      //   this.router.navigate(["login"]);
      // }
      if (this.platform.is("android")) {
        this.oneSignal.startInit(
          "34f4073b-fbcd-49ba-859e-e1502ed94b19",
          "207051934892"
        );

        this.oneSignal.inFocusDisplaying(
          this.oneSignal.OSInFocusDisplayOption.InAppAlert
        );

        this.oneSignal.handleNotificationReceived().subscribe(() => {
          // do something when notification is received
        });

        this.oneSignal.handleNotificationOpened().subscribe(() => {
          // do something when a notification is opened
        });

        this.oneSignal.endInit();

        this.deeplinks
          .route({
            "/orders/:status": OrdersPage,
            "/first": HomePage,
          })
          .subscribe(
            (match) => {
              // match.$route - the route we matched, which is the matched entry from the arguments to route()
              // match.$args - the args passed in the link
              // match.$link - the full link data
              this.userService.loadUser();
              this.router.navigate([match.$link.path.toString()]);
              console.log("Successfully matched route", match);
            },
            (nomatch) => {
              this.userService.loadUser();
              this.router.navigate(["first"]);
              // nomatch.$link - the full link data
              console.error("Got a deeplink that didn't match", nomatch);
            }
          );
        // // Convenience to route with a given nav
        // this.deeplinks
        //   .routeWithNavController(this.navCtrl, {
        //     "/orders/:status": OrdersPage,
        //     "/first": HomePage,
        //   })
        //   .subscribe(
        //     (match) => {
        //       console.log("Successfully routed", match);
        //     },
        //     (nomatch) => {
        //       console.warn("Unmatched Route", nomatch);
        //     }
        //   );
      }
      if (this.userService.getToken != null)
        this.isLoggedIn = this.userService.getToken.length > 0;
    });
  }
  async presentModal(pageId) {
    const modal = await this.modalController.create({
      animated: true,
      backdropDismiss: true,
      component: ModalContentPage,
      componentProps: {
        pageId: pageId,
      },
    });
    this.closeMenu();
    return await modal.present();
  }
}
