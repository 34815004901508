import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PlaylistService } from "../playlist.service";
import { ModalController, LoadingController } from "@ionic/angular";
// import { IPageMap } from "../interfaces/pageMap.interface";

@Component({
  selector: "app-modal-content",
  templateUrl: "./modal-content.page.html",
  styleUrls: ["./modal-content.page.scss"],
})
export class ModalContentPage implements OnInit {
  pageId: any;
  pageTitle: string;
  activePage: any;
  pagNdesc: any;
  evn: any;
  private loader: HTMLIonLoadingElement;
  private loaderLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private playlistService: PlaylistService,
    public loadingController: LoadingController,
    public modalController: ModalController
  ) {
    this.pageId = this.activatedRoute.snapshot.paramMap.get("pageId");
  }

  ngOnInit() {
    this.getdata();
  }

  public showLoading(message: string) {
    this.loaderLoading = true;
    this.loadingController
      .create({
        message,
        showBackdrop: true,
        backdropDismiss: true,
      })
      .then((load) => {
        this.loader = load;
        load.present().then(() => {
          this.loaderLoading = false;
        });
      });
  }

  public dismissLoading() {
    const interval = setInterval(() => {
      if (this.loader || !this.loaderLoading) {
        this.loader.dismiss().then(() => {
          this.loader = null;
          clearInterval(interval);
        });
      } else if (!this.loader && !this.loaderLoading) {
        clearInterval(interval);
      }
    }, 500);
  }

  // ionViewDidLoad() {
  //   this.loadPage();
  // }

  loadPage() {
    // this.playlistService
    //   .getPageFromCache(this.pageId)
    //   .subscribe((page: IPageMap) => {
    //     // if (page === undefined) {
    //     //   this.getdata();
    //     // } else {
    //     this.activePage = page;
    //     this.pageTitle = page.pageTitle;
    //     // this.pagNdesc = page.pagNdesc;
    //     this.pagNdesc = page.pagNdesc.replace("href", 'target="_system" href');
    //     // }
    //   });
  }
  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  doRefresh(event) {
    this.evn = event;
    this.getdata();
    // console.log('Begin async operation');

    // setTimeout(() => {
    //   console.log('Async operation has ended');
    //   event.target.complete();
    // }, 2000);
  }

  getdata() {
    this.showLoading("Bezig met laden...");
    // this.playlistService.loadPage(this.pageId);
    // this.playlistService.pageLoaded().subscribe((page: IPageMap) => {
    //   this.activePage = page;
    //   this.pageTitle = page.pageTitle;
    //   // this.pagNdesc = page.pagNdesc;
    //   this.pagNdesc = page.pagNdesc.replace("href", 'target="_system" href');
    //   if (this.evn != undefined) {
    //     this.evn.target.complete();
    //     // event.target.complete();
    //   }
    //   this.loadingController.dismiss();
    // });
  }
}
