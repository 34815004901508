import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../user.service";
import { IOrderMap } from "../interfaces/orderMap.interface"; 
import { ToastController } from "@ionic/angular";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.page.html",
  styleUrls: ["./orders.page.scss"],
})
export class OrdersPage implements OnInit {
  orders: IOrderMap[];
  status: string;
  tedad: number = 5;
  orderCount: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastController: ToastController
  ) {
    this.status = this.activatedRoute.snapshot.paramMap.get("status");
    userService.myOrders(this.tedad);
  }

  ngOnInit() {
    this.bindOrders();
  }

  myOrders(tedad) {
    this.userService.myOrders(tedad);
    this.bindOrders();
  }

  bindOrders() {
    this.userService.OrdersLoaded().subscribe((orders) => {
      console.log(orders);
      // if (this.status == "1") {
      //   this.orders = orders.filter(({ charityId }) => charityId == 0);
      // } else {
      //   this.orders = orders.filter(({ charityId }) => charityId > 0);
      // }
      this.orderCount = this.orders.length;
    });
  }

  // async presentToast(message) {
  //   const toast = await this.toastController.create({
  //     message: message,
  //     duration: 200000,
  //     position: "top",
  //     cssClass: "info",
  //   });
  //   toast.present();
  // }

  selectOrder(o1: IOrderMap) {
    // this.presentToast('لطفا چند لحظه صبر نمایید')
    localStorage.setItem("CurOrder", JSON.stringify(o1));
  }
}
