import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../user.service";
import { environment } from "src/environments/environment";
import { Platform } from "@ionic/angular";
import { IUserMap } from "../interfaces/userMap.interface";
// import { Device } from '@capacitor/core';

@Component({
  selector: "app-home",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
  isLoggedIn: boolean;
  today: string;
  avatar: string;
  evn: any;
  deinlieferbuddy: number = 0;
  deinlieferbuddyExpireRemainDay: number = 0;
  shopId: number = 0;
  environmentsrc: string;
  Deviceinfo: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private platform: Platform
  ) {
    this.router.navigate(["first"])
    this.userService.currentdeinlieferbuddyCount.subscribe(
      (deinlieferbuddyCount) => (this.deinlieferbuddy = deinlieferbuddyCount)
    );
    // this.route.queryParams.subscribe((params) => {
    //   console.log(params);
    // });
    // this.environmentsrc = environment.srclocal;
    this.environmentsrc = environment.src;
    if (this.platform.is("android")) {
      this.environmentsrc = environment.src;
    }
    // document.addEventListener(
    //   "backbutton",
    //   () => {
    //     navigator["app"].exitApp();
    //   },
    //   false
    // );
    if (this.userService.getToken != null)
      this.isLoggedIn = this.userService.getToken.length > 0;
    // if (this.isLoggedIn) this.router.navigate(["activedevice"]);
    if (!this.isLoggedIn) this.router.navigate(["login"]);
    this.bindUserInfo();
    userService.loadUser();
    userService.checkFriendRequest();
  }

  // processNextHandler(){
  //     console.log('Handler was called! processNextHandler');
  // }

  ngOnInit() {}

  bindUserInfo() {
    this.userService.shopIdLoaded().subscribe((shopId) => {
      console.log(shopId);
      this.shopId = shopId;
    });
    this.userService.todayLoaded().subscribe((today) => {
      console.log(today);
      this.today = today;
    });
    this.userService.userLoaded().subscribe((user) => {
      console.log(user);
      // this.deinlieferbuddy = user.deinlieferbuddy;
      // this.deinlieferbuddyExpireRemainDay = user.deinlieferbuddyExpireRemainDay;
    });

    this.avatar =
      this.environmentsrc +
      "/userPic/" +
      this.userService.userMap.avatar +
      "?rn=" +
      Math.floor(Math.random() * 1000);
    if (
      this.userService.userMap.avatar == "" ||
      this.userService.userMap.avatar == null
    ) {
      this.avatar = "../../assets/liefer.png";
    }
    // if (this.userService.userMap.accessRequest === 1) {
    //   var accessRequest = 1;
    //   if (
    //     confirm(
    //       "پشتیبانی درخواست دسترسی به حساب کاربری شما را دارد. آیا موافقت می نمایید؟"
    //     )
    //   ) {
    //     // alert("Confirm");
    //     accessRequest = 2;
    //   } else {
    //     // alert("عدم Confirm");
    //     accessRequest = 3;
    //   }
    //   const data: Object = {
    //     accessRequest: accessRequest,
    //   };
    //   this.userService.AccessRequestChange(data).subscribe((res) => {
    //     if (res.success) {
    //       // alert(res.message);
    //     } else {
    //       alert(res.message);
    //     }
    //   });
    // }
    if (this.evn != undefined) this.evn.target.complete();
  }

  doRefresh(event) {
    this.userService.loadUser();
    // this.showLoading("Loading...");
    this.evn = event;
    this.bindUserInfo();
    this.userService.checkFriendRequest();
    // event.target.complete();
  }
}
