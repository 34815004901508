import { Injectable } from "@angular/core";
import { Observable, of, Subject, timer, BehaviorSubject } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../environments/environment";
import { ILoginResponse } from "./interfaces/login-response.interface";
import { IUserMap } from "./interfaces/userMap.interface";
// import { IPageMap } from "./interfaces/pageMap.interface";
import { Platform, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { IUserAddressMap } from "./interfaces/userAddressMap.interface"; 
import { IOrderResponse } from "./interfaces/order-response.interface";
import { IOrderMap } from "./interfaces/orderMap.interface"; 
import { Device } from "@capacitor/core"; 
import { IUserActiveDeviceMap } from "./interfaces/userActiveDeviceMap.interface";
import { IUserFriendMap } from "./interfaces/userFriendMap.interface"; 
import { ITakhfifResponse } from "./interfaces/takhfif-response.interface";
import { ITakhfifMap } from "./interfaces/takhfifMap.interface"; 
import { IPageCommentMap } from "./interfaces/pageCommentMap.interface";
import { IOrderReq } from "./interfaces/orderReq.interface";

export interface IUser {
  // login: string;
  username: string;
  password: string;
  deviceInfo: string;
  // lastIp: string;
  access_token: string;
}

@Injectable()
export class UserService {
  private _user: IUser | null;
  private _userMap: IUserMap | null;
  private _isLoggedIn: boolean;
  private _token: string;

  public _today: string;
  private todayLoaded$: Subject<string> = new Subject<string>();

  public _shippingday: string;
  private shippingdayLoaded$: Subject<string> = new Subject<string>();

  public _shopId: number;
  private shopIdLoaded$: Subject<number> = new Subject<number>();

  public _bazarId: number;
  private bazarIdLoaded$: Subject<number> = new Subject<number>();

  public _currentUser: IUserMap;
  private currentUserLoaded$: Subject<IUserMap> = new Subject<IUserMap>();

  private userContext$: Subject<IUser> = new Subject<IUser>();

  private userMapContext$: Subject<IUserMap> = new Subject<IUserMap>();

  public _currentOrders: Array<IOrderMap>;
  private currentOrdersLoaded$: Subject<Array<IOrderMap>> = new Subject<
    Array<IOrderMap>
  >();

  public _currentOrder: IOrderMap;
  private currentOrderLoaded$: Subject<IOrderMap> = new Subject<IOrderMap>();

  public _currentTakhfif: ITakhfifMap;
  private currentTakhfifLoaded$: Subject<ITakhfifMap> =
    new Subject<ITakhfifMap>();
 

  public _currentFriends: Array<IUserFriendMap>;
  private currentFriendsLoaded$: Subject<Array<IUserFriendMap>> = new Subject<
    Array<IUserFriendMap>
  >();
  
  private CartSource = new BehaviorSubject([]);
  currentCart = this.CartSource.asObservable();

  environmenturl: string;
  environmentsrc: string;

  //   private messageSource = new BehaviorSubject("default message");
  //   currentMessage = this.messageSource.asObservable();

  //   private AuthSource = new BehaviorSubject(false);
  //   currentAuth = this.AuthSource.asObservable();
  
  private CartCountCharitySource = new BehaviorSubject(0);
  currentCartCountCharity = this.CartCountCharitySource.asObservable();

  private CartCountSource = new BehaviorSubject(0);
  currentCartCount = this.CartCountSource.asObservable();

  private deinlieferbuddyCountSource = new BehaviorSubject(0);
  currentdeinlieferbuddyCount = this.deinlieferbuddyCountSource.asObservable();

  private MsgCountSource = new BehaviorSubject(0);
  currentMsgCount = this.MsgCountSource.asObservable();

  private FriendCountSource = new BehaviorSubject(0);
  currentFriendCount = this.FriendCountSource.asObservable();

  private DiscountCountSource = new BehaviorSubject(0);
  currentDiscountCount = this.DiscountCountSource.asObservable();

  Deviceinfo: any;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private platform: Platform,
    private toastController: ToastController
  ) {
    this.environmenturl = environment.apilocal;
    this.environmentsrc = environment.srclocal;
    if (platform.is("android") || 1 == 1) {
      this.environmenturl = environment.api;
      this.environmentsrc = environment.src;
    }
  }

  changeCartCount(cartCount: number) {
    this.CartCountSource.next(cartCount);
  }

  changeCartCountCharity(cartCountCharity: number) {
    this.CartCountSource.next(cartCountCharity);
  }

  changedeinlieferbuddyCount(deinlieferbuddyCount: number) {
    this.deinlieferbuddyCountSource.next(deinlieferbuddyCount);
  }

  changeMsgCount(msgCount: number) {
    this.MsgCountSource.next(msgCount);
  }

  changeFriendCount(FriendCount: number) {
    this.FriendCountSource.next(FriendCount);
  }

  changeDiscountCount(Discount: number) {
    this.DiscountCountSource.next(Discount);
  }

  //   changeAuth(isAuth: boolean) {
  //     this.AuthSource.next(isAuth);
  //   }

  //   changeMessage(message: string) {
  //     this.messageSource.next(message);
  //   }
 
  public FriendsLoaded(): Observable<Array<IUserFriendMap>> {
    return this.currentFriendsLoaded$;
  }
 
  public OrdersLoaded(): Observable<Array<IOrderMap>> {
    return this.currentOrdersLoaded$;
  }

  public OrderLoaded(): Observable<IOrderMap> {
    return this.currentOrderLoaded$;
  }

  public userLoaded(): Observable<IUserMap> {
    return this.currentUserLoaded$;
  }

  public takhfifLoaded(): Observable<ITakhfifMap> {
    return this.currentTakhfifLoaded$;
  }

  public todayLoaded(): Observable<string> {
    return this.todayLoaded$;
  }

  public shippingdayLoaded(): Observable<string> {
    return this.shippingdayLoaded$;
  }

  public shopIdLoaded(): Observable<number> {
    return this.shopIdLoaded$;
  }

  public changeShop(shopId: number) {
    var shopMapStr = localStorage.getItem("acticeShop");
    if (shopMapStr != null) {
      var shopMap = JSON.parse(shopMapStr);
      if (shopMap.shopId != shopId) {
        localStorage.removeItem("acticeShop");
        localStorage.removeItem("CartList");
      }
    }
    this._shopId = shopId;
    this.shopIdLoaded$.next(this._shopId);
  }

  public changeBazar(bazarId: number) {
    var bazarMapStr = localStorage.getItem("acticeBazar");
    if (bazarMapStr != null) {
      var bazarMap = JSON.parse(bazarMapStr);
      if (bazarMap != null)
        if (bazarMap.bazarId != bazarId) {
          localStorage.removeItem("acticeBazar");
          localStorage.removeItem("CartList");
        }
    }
    this._bazarId = bazarId;
    this.bazarIdLoaded$.next(this._bazarId);
  }

  public async loadUser() {
    this.Deviceinfo = await Device.getInfo();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    this.Deviceinfo.uuid = "";
    var req = { Deviceinfo: JSON.stringify(this.Deviceinfo) };
    return this.httpClient
      .post(`${this.environmenturl}/User/getUserInfo`, req, options)
      .subscribe(
        (res: ILoginResponse) => {
          if (res.success) {
            if (this.Deviceinfo.platform != "web") {
              if (environment.version !== res.lastVersion) {
                this.presentToast("Die neue Version ist verfügbar, bitte installieren Sie sie!");
                // this.presentToast("نسخه جدید در دسترس می باشد لطفا نصب نمایید!");
                setTimeout(() => {
                  window.open(
                    "http://cdn.delivery.com/apk/deinlieferbuddy.apk",
                    "_self",
                    "location=yes"
                  );
                }, 2000);
                return res;
              }
            }
            if (res.isActive) {
              localStorage.setItem("LoginResponse", JSON.stringify(res));
              // this.changedeinlieferbuddyCount(res.userMap.deinlieferbuddy);
              this.userMap = res.userMap;
              this._currentUser = res.userMap;
              this.currentUserLoaded$.next(this._currentUser);
              this._today = res.today;
              this.todayLoaded$.next(this._today);
              this._shippingday = res.shippingday;
              this.shippingdayLoaded$.next(this._shippingday);
              // this._shopId = res.userMap.shopId;
              this.shopIdLoaded$.next(this._shopId);
              // var MsgCountNotSeen = res.userMap.pageCommentDTOMaps.filter(
              //   ({ pageCommentStatus, userId, pageCommentType }) =>
              //     // (pageCommentStatus != 2 && pageCommentType === 9) ||
              //     pageCommentType < 7 &&
              //     pageCommentStatus != 2 &&
              //     pageCommentStatus != 3
              // );
              // this.changeMsgCount(MsgCountNotSeen.length);
              // this._currentMessages = res.userMap.pageCommentDTOMaps;
              // this.currentMessagesLoaded$.next(res.userMap.pageCommentDTOMaps);
              // var msgRes = {
              //   pageCommentDTOMaps: res.userMap.pageCommentDTOMaps,
              // };
              // localStorage.setItem("MessagesResponse", JSON.stringify(msgRes));
            } else {
              this.router.navigate(["login"]);
              // alert(res.message);
              this.presentToast(res.message);
            }
          } else {
            this.router.navigate(["login"]);
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            // this.router.navigate(["login"]);
            this.router.onSameUrlNavigation = "reload";
            this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
  }

  public GetTakhfifOrg(code) {
    // this.Deviceinfo = await Device.getInfo();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    // this.Deviceinfo.uuid = "";
    // var req = { Deviceinfo: JSON.stringify(this.Deviceinfo) };
    return this.httpClient
      .post(`${this.environmenturl}/User/GetTakhfifOrg/` + code, null, options)
      .pipe(
        switchMap((res: any) => {
          if (res.success) {
          }
          return of(res);
        })
      );
  }
  
  public loadFirstData(type,tedad) {
    // this.Deviceinfo = await Device.getInfo();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    // this.Deviceinfo.uuid = "";
    // var req = { Deviceinfo: JSON.stringify(this.Deviceinfo) };
    var rout = type + '/' + tedad;
    return this.httpClient
      .post(`${this.environmenturl}/User/MyOrders/` + rout , null, options)
      .pipe(
        switchMap((res: any) => {
          if (res.success) {
          }
          return of(res);
        })
      );
    // .subscribe(
    //   (res: any) => {
    //     if (res.success) {

    //     } else {
    //       // this.router.navigate(["login"]);
    //     }
    //     localStorage.setItem(
    //       "firstData",
    //       JSON.stringify(res)
    //     );
    //     return res;
    //   },
    //   // Errors will call this callback instead:
    //   (err: HttpErrorResponse) => {
    //     if (err.status == 401) {
    //       this.logout();
    //       this.router.navigate(["login"]);
    //     }
    //     console.log(err);
    //   }
    // );
  }

  public delOrder(orderId) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/delOrder/` + orderId , null, options)
      .pipe(
        switchMap((res: any) => {
          if (res.success) {
          }
          return of(res);
        })
      ); 
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: "top",
    });
    toast.present();
  }

  public get user() {
    return this._user;
  }

  public set user(user: IUser) {
    this._user = user;
  }

  public get userMap() {
    var LoginResponse = localStorage.getItem("LoginResponse");
    if (LoginResponse != undefined)
      this._userMap = JSON.parse(LoginResponse).userMap;
    return this._userMap;
  }

  public set userMap(userMap: IUserMap) {
    this._userMap = userMap;
  }

  public get isLoggedIn(): boolean {
    if (this._token == undefined) this._token = localStorage.getItem("token");
    return this._token != undefined;
    // return this._isLoggedIn;
  }

  public set isLoggedIn(state: boolean) {
    this._isLoggedIn = state;
  }

  public get getToken(): string {
    if (this._token == undefined) {
      this._token = localStorage.getItem("token");
    }
    return this._token;
  }
 
  public myOrders(tedad) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/MyOrders/` + tedad, null, options)
      .subscribe(
        (res: IOrderResponse) => {
          if (res.success) {
            this._currentOrders = res.orderMaps;
            this.currentOrdersLoaded$.next(res.orderMaps);
            localStorage.setItem("OrderResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
            //this.router.navigate(["first"]);
          }else{
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
    // .pipe(
    //   switchMap((response: IOrderResponse) => {
    //     if (response.success) {
    //       this._currentOrders=response.orderMaps;
    //       this.currentOrdersLoaded$.next(response.orderMaps);
    //       localStorage.setItem("OrderResponse", JSON.stringify(response));
    //     }
    //     return of(response);
    //   })
    // );
  }

  public myOrderDetail(OrderId) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(
        `${this.environmenturl}/User/MyOrderDetail/` + OrderId,
        null,
        options
      )
      .subscribe(
        (res: IOrderResponse) => {
          if (res.success) {
            this._currentOrder = res.orderMap;
            this.currentOrderLoaded$.next(res.orderMap);
            // localStorage.setItem("OrderResponse", JSON.stringify(res));
            localStorage.setItem("CurOrder", JSON.stringify(res.orderMap));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
            //this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
    // .pipe(
    //   switchMap((response: IOrderResponse) => {
    //     if (response.success) {
    //       this._currentOrders=response.orderMaps;
    //       this.currentOrdersLoaded$.next(response.orderMaps);
    //       localStorage.setItem("OrderResponse", JSON.stringify(response));
    //     }
    //     return of(response);
    //   })
    // );
  }

  public myOrderDetails(OrderId) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(
        `${this.environmenturl}/User/MyOrderDetail/` + OrderId,
        null,
        options
      )
      .subscribe(
        (res: IOrderResponse) => {
          if (res.success) {
            this._currentOrder = res.orderMap;
            this.currentOrderLoaded$.next(res.orderMap);
            // localStorage.setItem("OrderResponse", JSON.stringify(res));
            localStorage.setItem("CurOrder", JSON.stringify(res.orderMap));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
            //this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
    // .pipe(
    //   switchMap((response: IOrderResponse) => {
    //     if (response.success) {
    //       this._currentOrders=response.orderMaps;
    //       this.currentOrdersLoaded$.next(response.orderMaps);
    //       localStorage.setItem("OrderResponse", JSON.stringify(response));
    //     }
    //     return of(response);
    //   })
    // );
  }

  public loadTakhfif() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    // var shopCart = localStorage.getItem("CartList");
    // this.pages = JSON.parse(CartList);
    // var req = { shopCart: shopCart };
    return this.httpClient
      .post(`${this.environmenturl}/User/GetTakhfifs`, null, options)
      .subscribe(
        (res: ITakhfifResponse) => {
          if (res.success) {
            this._currentTakhfif = res.takhfifMap;
            this.currentTakhfifLoaded$.next(res.takhfifMap);
            localStorage.setItem("TakhfifResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
            //this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
  }

  public AccessRequestChange(user: Object) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/UserTb_AccessRequest`, user, options)
      .pipe(
        switchMap((res: any) => {
          if (res.success) {
            // this._currentFriends = res.userFriendMaps;
            // this.currentFriendsLoaded$.next(res.userFriendMaps);
            // localStorage.setItem("FriendsResponse", JSON.stringify(res));
          }
          return of(res);
        })
      );
  }

  public InviteFriend(user: IUserFriendMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/UserFriend_post`, user, options)
      .pipe(
        switchMap((res: any) => {
          if (res.success) {
            this._currentFriends = res.userFriendMaps;
            this.currentFriendsLoaded$.next(res.userFriendMaps);
            localStorage.setItem("FriendsResponse", JSON.stringify(res));
          }
          return of(res);
        })
      );
    // .subscribe(
    //   (res: any) => {
    //     if (res.success) {
    //       this._currentFriends = res.userFriendMaps;
    //       this.currentFriendsLoaded$.next(res.userFriendMaps);
    //       localStorage.setItem("FriendsResponse", JSON.stringify(res));
    //     }else {
    //       alert(res.message);
    //     }
    //     return res;
    //   },
    //   // Errors will call this callback instead:
    //   (err: HttpErrorResponse) => {
    //     if (err.status == 401) {
    //       this.logout();
    //       this.router.navigate(["login"]);
    //     }
    //     console.log(err);
    //   }
    // );
  }

  public checkFriendRequest() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/checkFriendRequest`, null, options)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.changeFriendCount(res.moarefCount);
          } else {
            this.changeMsgCount(0);
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
            //this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
  }

  public UserFriend_status(user: IUserFriendMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/UserFriend_status`, user, options)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this._currentFriends = res.userFriendMaps;
            this.currentFriendsLoaded$.next(res.userFriendMaps);
            localStorage.setItem("FriendsResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
    // .pipe(
    //   switchMap((response: IOrderResponse) => {
    //     if (response.success) {
    //       this._currentOrders=response.orderMaps;
    //       this.currentOrdersLoaded$.next(response.orderMaps);
    //       localStorage.setItem("OrderResponse", JSON.stringify(response));
    //     }
    //     return of(response);
    //   })
    // );
  }

  public myFriends() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/UserFriend_list`, null, options)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this._currentFriends = res.userFriendMaps;
            this.currentFriendsLoaded$.next(res.userFriendMaps);
            localStorage.setItem("FriendsResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
  }

  public checkDiscountRequest() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/checkDiscountRequest`, null, options)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.changeDiscountCount(res.moarefCount);
          } else {
            this.changeMsgCount(0);
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
  }
 
  public myMessagesOrder(orderId) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(
        `${this.environmenturl}/User/commentOrder_list` + orderId,
        null,
        options
      )
      .subscribe(
        (res: any) => {
          if (res.success) { 
            localStorage.setItem("MessagesResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
  }

  public myMessages(msgId: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/comment_list/` + msgId, null, options)
      .subscribe(
        (res: any) => {
          if (res.success) { 
            localStorage.setItem("MessagesResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
  }

  public myFaqMessages() {
    const headers = new HttpHeaders({
      // Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/commentFaq_list/`, null, options)
      .subscribe(
        (res: any) => {
          if (res.success) { 
            localStorage.setItem("FaqMessagesResponse", JSON.stringify(res));
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.logout();
            this.router.navigate(["login"]);
          }
          console.log(err);
        }
      );
  }

  public charitiesbazar() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/charitiesbazar_List/`, null, options)
      .pipe(
        switchMap((response: any) => {
          if (response.success) {
            localStorage.setItem("charitiesbazarResponse", JSON.stringify(response));
          }
          return of(response);
        }),
        catchError((error) => {
          return of(null);
        })
      );
    // .subscribe(
    //   (res: any) => {
    //     if (res.success) {
    //       // this._shopId = 0;
    //       // this.shopIdLoaded$.next(0);
    //       // this._currentCharities = res.charityMaps;
    //       // this.currentCharitiesLoaded$.next(res.charityMaps);
    //       localStorage.setItem(
    //         "charitiesbazarResponse",
    //         JSON.stringify(res.bazarMaps)
    //       );
    //     }
    //     return res;
    //   },
    //   // Errors will call this callback instead:
    //   (err: HttpErrorResponse) => {
    //     if (err.status == 401) {
    //       this.logout();
    //       this.router.navigate(["login"]);
    //     }
    //     console.log(err);
    //   }
    // );
  }
 
 
  public postOrder(req: IOrderReq) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return (
      this.httpClient
        // .post(`${this.environmenturl}/User/OrderPay`, req, options)
        .post(`${this.environmenturl}/User/orderDelivery`, req, options)
        .pipe(
          switchMap((response: IOrderResponse) => {
            if (response.success) {
              // this.userMap = response.userMap;
              // this.userMapContext$.next(this.userMap);
              // localStorage.setItem("LoginResponse", JSON.stringify(response));
            }
            return of(response);
          }),
          catchError((error) => {
            return of(null);
          })
        )
    );
  }

  public ConfirmOrder(orderID: number) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    const req = {
      orderID: orderID,
    };

    this.httpClient
      .post(`${this.environmenturl}/User/OrderConfirm`, req, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            alert("Confirm با موفقیت انجام شد");
            // document.location.reload();
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe(
        (res: ILoginResponse) => {
          if (res.success) {
          } else {
            // this.router.navigate(["login"]);
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
  }

  public ReturnOrder(
    orderID: number,
    status: number,
    PostCodeRahgiriReturn: string,
    postReturnDesc: string,
    postUrlReturn: string
  ) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    const req = {
      orderID: orderID,
      status: status,
      PostCodeRahgiriReturn: PostCodeRahgiriReturn,
      postReturnDesc: postReturnDesc,
      postUrlReturn: postUrlReturn,
    };

    this.httpClient
      .post(`${this.environmenturl}/User/ReturnOrder`, req, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            if (status == 6) alert("درخواست مرجوعی سفارش با موفقیت انجام شد");
            else if (status == 9)
              alert("ارسال مرجوعی سفارش با موفقیت انجام شد");

            // document.location.reload();
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        }),
        catchError((error) => {
          return of(null);
        })
      )
      .subscribe(
        (res: ILoginResponse) => {
          if (res.success) {
          } else {
            // this.router.navigate(["login"]);
          }
          return res;
        },
        // Errors will call this callback instead:
        (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.router.navigate(["first"]);
          }
          console.log(err);
        }
      );
  }

  public ReturnOrderImage(
    orderID: number,
    status: number,
    base64ImageStr: string
  ) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
      "Content-Type": "application/json",
    });
    const options = {
      headers: headers,
    };
    var req = {
      orderID: orderID,
      status: status,
      ReturnImgBase64String: base64ImageStr,
    }; //JSON.stringify(req)
    return this.httpClient
      .post(`${this.environmenturl}/User/ReturnOrder`, req, options)
      .pipe(
        switchMap((response: IOrderResponse) => {
          if (response.success) {
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public set setToken(state: string) {
    this._token = state;
  }
 
  // public BazarUserClick(sp: IBazarMap) {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.getToken}`,
  //   });
  //   const options = {
  //     headers: headers,
  //   };
  //   return this.httpClient
  //     .post(`${this.environmenturl}/User/BazarUserClick_post`, sp, options)
  //     .pipe(
  //       switchMap((response: ILoginResponse) => {
  //         if (response.success) {
  //         }
  //         return of(response);
  //       })
  //     );
  // }

  public logout() {
    this._token = null;
    localStorage.clear();
    // this.changeAuth(false);
  }

  public loginAs(user: any) {
    return this.httpClient
      .post(`${this.environmenturl}/User/PostTokenLoginAs`, user)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.user = {
              username: user.username,
              password: user.password,
              access_token: response.access_token,
              deviceInfo: user.deviceInfo,
              // lastIp:user.lastIp,
            };
            this.userContext$.next(this.user);
            this.userMapContext$.next(this.userMap);
            this.isLoggedIn = true;
            localStorage.setItem("LoginResponse", JSON.stringify(response));
            localStorage.setItem("token", response.access_token);
            this.setToken = response.access_token;
            //   this.changeAuth(true);
          }
          // return of(this.isLoggedIn);
          return of(response);
        })
      );
  }

  public login(user: IUser) {
    return this.httpClient
      .post(`${this.environmenturl}/User/PostToken`, user)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.user = {
              username: user.username,
              password: user.password,
              access_token: response.access_token,
              deviceInfo: user.deviceInfo,
              // lastIp:user.lastIp,
            };
            this.userContext$.next(this.user);
            this.userMapContext$.next(this.userMap);
            this.isLoggedIn = true;
            localStorage.setItem("LoginResponse", JSON.stringify(response));
            localStorage.setItem("token", response.access_token);
            this.setToken = response.access_token;
            //   this.changeAuth(true);
          }
          // return of(this.isLoggedIn);
          return of(response);
        })
      );
  }

  public forget(user: IUser) {
    return this.httpClient
      .post(`${this.environmenturl}/User/Forget`, user)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
          }
          // return of(this.isLoggedIn);
          return of(response);
        })
      );
  }

  public register(user: IUserMap) {
    return this.httpClient
      .post(`${this.environmenturl}/User/register`, user)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public UserUpdate(user: IUserMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/UserUpdate`, user, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public nidUpdate(user) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/nidUpdate`, user, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public changepass(user: IUserMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/changepass`, user, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public ActivatePass(user: IUserMap) {
    // const headers = new HttpHeaders({
    //   Authorization: `Bearer ${this.getToken}`,
    // });
    // const options = {
    //   headers: headers,
    // };
    return this.httpClient
      .post(`${this.environmenturl}/User/ActivatePass_post`, user)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public activeAdr(userAdr: IUserAddressMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/activeAdr`, userAdr, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
  }

  public postAdr(userAdr: IUserAddressMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/postAdr`, userAdr, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
  }

  public removeAdr(userAdr: IUserAddressMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/removeAdr`, userAdr, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
  }

  public messageSeen() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/messageSeen`, null, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.changeMsgCount(0);
          }
          return of(response);
        })
      );
  }

  public changeusername(user: IUserMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/changeusername`, user, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public sendMessage(pcomment: IPageCommentMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/comment_post`, pcomment, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
          }
          return of(response);
        })
      );
  }

  public deactivate(data: IUserMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/deactivate`, data, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
  }

  public invitors() {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/invitors`, null, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
  }

  public transfer(user: IUserMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/transfer`, user, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            // this.userMap = response.userMap;
            // this.userMapContext$.next(this.userMap);
            // localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public removeDevice(device: IUserActiveDeviceMap) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
    });
    const options = {
      headers: headers,
    };
    return this.httpClient
      .post(`${this.environmenturl}/User/removeDevice`, device, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public avatar(base64ImageStr: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.getToken}`,
      "Content-Type": "application/json",
    });
    const options = {
      headers: headers,
    };
    var req = { avatar: base64ImageStr }; //JSON.stringify(req)
    return this.httpClient
      .post(`${this.environmenturl}/User/avatar`, req, options)
      .pipe(
        switchMap((response: ILoginResponse) => {
          if (response.success) {
            this.userMap = response.userMap;
            this.userMapContext$.next(this.userMap);
            localStorage.setItem("LoginResponse", JSON.stringify(response));
          }
          return of(response);
        })
      );
    // return this.httpClient.post(`${this.environmenturl}/User/register`, user);
  }

  public userContextChanges(): Observable<IUser> {
    return this.userContext$;
  }
}
