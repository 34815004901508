import { Injectable } from "@angular/core";
import { Observable, Subject, of } from "rxjs";
// import { ISong } from './player/player-page.component';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "../environments/environment";
// import { IPageResponse } from './interfaces/pagecat-response.interface'; 
import { UserService, IUser } from "./user.service";
import { switchMap } from "rxjs/operators";
import { Router } from "@angular/router"; 
import { Platform } from "@ionic/angular"; 

@Injectable({
  providedIn: "root",
})
export class PlaylistService {
  environmenturl: string;
  environmentsrc: string;

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
    private router: Router,
    private platform: Platform
  ) {
    this.environmenturl = environment.apilocal;
    this.environmentsrc = environment.srclocal;
    if (platform.is("android") || 1 == 1) {
      this.environmenturl = environment.api;
      this.environmentsrc = environment.src;
    }
    // this.loadSongs();
  }
  // public _currentPlayedSong: ISong;
  // private currentPlayedSong$: Subject<ISong> = new Subject<ISong>();

  // public _songsList: ISong[];
  private songsList$: Subject<any> = new Subject<any>();
  // private songsLoaded$: Subject<ISong[]> = new Subject<ISong[]>();
      
  // public loadProvinces() {
  //   // const headers = new HttpHeaders({
  //   //   Authorization: `Bearer ${this.userService.getToken}`,
  //   // });
  //   // const options = {
  //   //   headers: headers,
  //   // };
  //   this.httpClient
  //     .post(`${this.environmenturl}/User/ProvinceTb_List`, null)
  //     .subscribe((res: any) => {
  //       if (res.success) {
  //         localStorage.setItem("provinces", JSON.stringify(res.provinceMaps));
  //       }
  //     });
  // }
  
  public _addStandardHeaders() {
    var header = new HttpHeaders();
    header.append("Content-Type", "application/json");
    header.append("Accept", "application/json");
    header.append("Authorization", "Bearer " + this.userService.getToken);
    return header;
  }
}
